import { isAfter, isEqual } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const showPublishButton = ({isImageAdded, inventory_level}) => {
    if(isImageAdded && inventory_level > 0) {
        return true
    }
};

const isAfterOrEqual = (date1, date2) => {
    return isAfter(date1, date2) || isEqual(date1, date2);
}

const getPublishAttributes = (dateCreatedStr, publishDelay, condition) => {   
    if(condition == "Used") {
        const parsedDate = new Date(`${dateCreatedStr} UTC`);
        parsedDate.setDate(parsedDate.getDate() + publishDelay);
        const localDateObject = utcToZonedTime(parsedDate, Intl.DateTimeFormat().resolvedOptions().timeZone);
    
        if (isAfterOrEqual(new Date(), localDateObject)) {
            return { is_featured: true, is_visible: true };
        } else {
            return { is_featured: true };
        }
    } else {
        return { is_featured: true, is_visible: true };
    }
}

export { showPublishButton,  getPublishAttributes };
